import React, { createContext, useState } from 'react';
import { Transition } from '@headlessui/react';

export const ModalContext = createContext({ toggle: () => {} });

const ControlledModal = ({ children, show, toggleShow }) => {
	return (
		<Transition
			show={show}
			enter="ease-out duration-300"
			enterFrom="opacity-0"
			enterTo="opacity-100"
			leave="ease-in duration-200"
			leaveFrom="opacity-100"
			leaveTo="opacity-0"
		>
			{ref => (
				<div ref={ref} className="fixed z-10 inset-0 overflow-y-auto">
					<div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
						<div className="fixed inset-0 transition-opacity">
							<div className="absolute inset-0 bg-gray-500 opacity-75" onClick={toggleShow}></div>
						</div>
						{/* <!-- This element is to trick the browser into centering the modal contents. --> */}
						<span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
						{/*   Modal panel, show/hide based on modal state. */}
						<Transition
							show={show}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							{ref => (
								<div
									ref={ref}
									className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle "
									role="dialog"
									aria-modal="true"
									aria-labelledby="modal-headline"
								>
									<div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
										<div className="sm:flex sm:items-start">{children}</div>
									</div>
								</div>
							)}
						</Transition>
					</div>
				</div>
			)}
		</Transition>
	);
};

export const Modal = ({ children, trigger }) => {
	const [show, setShow] = useState(false);

	const toggle = () => setShow(!show);
	return (
		<>
			<ModalContext.Provider value={{ toggle }}>
				<ControlledModal show={show} toggleShow={toggle}>
					{children}
				</ControlledModal>
				{trigger(toggle)}
			</ModalContext.Provider>
		</>
	);
};

export default Modal;
