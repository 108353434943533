import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import addToMailchimp from 'gatsby-plugin-mailchimp';

const EmailSignup = ({}) => {
	const [submitted, setSubmitted] = useState(false);
	const [success, setSuccess] = useState(false);

	if (!(submitted && !success)) {
		return (
			<Formik
				initialValues={{
					email: '',
				}}
				onSubmit={async values => {
					try {
						const data = await addToMailchimp(values.email);
						console.log(data);
						setSuccess(true);
					} catch (e) {
						setSuccess(false);
					}

					setSubmitted(true);
				}}
			>
				<Form className="shadow p-4 h-full w-full rounded m-auto bg-white flex flex-col items-center">
					<h3 className="font-semibold text-md text-center text-lg">Join my email list</h3>
					<p className="text-sm my-2">Be the first to receive my latest content and opt-out at anytime.</p>
					{submitted && success ? (
						<p>Thanks for subscribing</p>
					) : (
						<div>
							<Field type="email" name="email" className="p-2 mr-2 form-input" placeholder="your@email.com" required />
							<button className="clickable bg-blue-500 p-2 text-white" type="submit">
								Subscribe
							</button>
						</div>
					)}
				</Form>
			</Formik>
		);
	}

	return (
		<div className="shadow p-4 h-full w-full rounded m-auto bg-white flex flex-col items-center">
			<h2 className="text-center text-lg font-bold mb-5">Form could not be submitted</h2>
			<div className="mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full bg-red-100 sm:mx-0 mb-5">
				<svg
					className="h-10 w-10 text-red-600"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
						d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
					/>
				</svg>
			</div>
			<p className="text-center mb-5">Check your internet connection and try again in a few moments</p>
			<button className="clickable mb-2 bg-blue-500 py-2 px-3 text-white" onClick={() => setSubmitted(false)}>
				Dismiss
			</button>
		</div>
	);
};

export default EmailSignup;
