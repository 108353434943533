import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import ContactUs from './assets/undraw_contact_us_15o2';
import qs from 'qs';
import axios from 'axios';

const FullContactForm = ({ onSuccess }) => (
	<div className="flex flex-col items-center md:flex-row md:items-between justify-between">
		<div className="flex flex-col justify-between md:mr-8 md:w-96">
			<h2 className="text-center text-4xl lg:text-5xl font-bold mb-5">Let's Chat</h2>
			<div className="mb-4">
				<ContactUs className="w-full h-1/2" />
			</div>
		</div>
		<ContactForm onSuccess={onSuccess} />
	</div>
);

const ContactForm = ({ onSuccess }) => {
	const [submitted, setSubmitted] = useState(false);
	const [success, setSuccess] = useState(false);

	if (!(submitted && !success)) {
		return (
			<Formik
				initialValues={{
					fullname: '',
					email: '',
					message: '',
				}}
				onSubmit={async values => {
					try {
						await axios.post('/', qs.stringify({ ...values, 'form-name': 'contact' }), {
							headers: { 'content-type': 'application/x-www-form-urlencoded' },
						});
						setSuccess(true);
						onSuccess();
					} catch (e) {
						console.error(e);
						setSuccess(false);
					}
					setSubmitted(true);
				}}
			>
				<FormFields />
			</Formik>
		);
	}

	return (
		<div className="flex flex-col w-full md:w-72 items-center">
			<h2 className="text-center text-3xl font-bold mb-5">Form could not be submitted</h2>
			<div className="mx-auto flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full bg-red-100 sm:mx-0 mb-5">
				<svg
					className="h-10 w-10 text-red-600"
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						strokeWidth="2"
						d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
					/>
				</svg>
			</div>
			<p className="text-center mb-5">Check your internet connection and try again in a few moments</p>
			<button className="clickable mb-2 bg-blue-500 py-2 px-3 text-white" onClick={() => setSubmitted(false)}>
				Dismiss
			</button>
		</div>
	);
};

const FormFields = () => (
	<Form className="flex flex-col w-full md:w-72">
		<div className="mb-4">
			<label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="fullname">
				Full Name
			</label>
			<Field
				className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
				name="fullname"
				id="fullname"
				type="text"
				required
			/>
		</div>
		<div className="mb-4">
			<label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
				Email
			</label>
			<Field
				className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
				name="email"
				id="email"
				type="email"
				required
			/>
		</div>
		<div className="mb-4">
			<label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="message">
				Message
			</label>
			<Field
				as="textarea"
				className="shadow appearance-none border rounded w-full h-32 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
				name="message"
				id="message"
				required
			></Field>
		</div>

		<button className="clickable bg-blue-500 py-2 text-white" type="submit">
			Send
		</button>
	</Form>
);

export const NetlifyForm = () => (
	<form name="contact" data-netlify netlify-honeypot="bot-field" hidden>
		<input type="text" name="fullname" />
		<input type="email" name="email" />
		<textarea name="message"></textarea>
	</form>
);

export default FullContactForm;
