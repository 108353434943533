import React from 'react';
import Modal, { ModalContext } from '../components/modal';
import ContactForm, { NetlifyForm } from '../components/contact-form';

const ContactModal = ({ buttonClassName = '' }) => {
	return (
		<Modal
			trigger={toggle => (
				<>
					<button className={`cta-contact-launch ${buttonClassName}`} onClick={toggle}>
						Contact Me
					</button>
					<NetlifyForm />
				</>
			)}
		>
			<ModalContext.Consumer>{({ toggle }) => <ContactForm onSuccess={toggle} />}</ModalContext.Consumer>
		</Modal>
	);
};

export default ContactModal;
