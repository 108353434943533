import { graphql, Link } from 'gatsby';
import React from 'react';
import Layout from '../components/layout';
import { Head } from '../components/layout/head';
import ContactModal from '../components/contact-modal';
import EmailSignup from '../components/email-signup';
import Image from 'gatsby-image';
import { DiscussionEmbed } from 'disqus-react';

const BlogPostTemplate = ({ data, pageContext }) => {
	const { previous, next, slug } = pageContext;

	const { post, author, site } = data;
	const featuredImage = post.frontmatter.featuredImage;

	return (
		<Layout>
			<Head
				title={post.frontmatter.title}
				description={post.frontmatter.description || post.excerpt}
				previewImage={featuredImage ? featuredImage.childImageSharp.fixed.src : ''}
				isBlogPost={true}
				slug={slug}
				datePublished={post.frontmatter.date}
			/>

			<div className="max-w-screen-lg mx-auto">
				<Article {...post.frontmatter} author={author.frontmatter.name} readingTime={post.fields.readingTime.text}>
					<section
						className="markdown  text-lg md:text-base tracking-wide leading-relaxed md:leading-loose text-smoke-900"
						dangerouslySetInnerHTML={{ __html: post.html }}
					/>
				</Article>

				<hr className="mb-8" />

				<div className="flex items-start">
					<Image
						className={`flex-shrink-0 self-center h-20 w-20 rounded shadow-md`}
						fixed={author.frontmatter.avatar.childImageSharp.fixed}
						alt={author.frontmatter.name}
					/>
					<aside className={`flex flex-col h-full ml-5 md:w-1/3`}>
						<div>
							<h3 className="mt-0 mb-1 font-semibold text-md text-center md:text-left ">{author.frontmatter.name}</h3>
							<p className="markdown text-sm mb-0" dangerouslySetInnerHTML={{ __html: author.html }} />
						</div>
						<ContactModal buttonClassName="self-center md:self-start clickable bg-blue-500 text-white px-2 py-1" />
					</aside>
				</div>

				<hr className="my-8" />

				<EmailSignup />

				<hr className="my-8" />

				<DiscussionEmbed
					shortname="sidneyw-com"
					config={{
						url: `${site.siteMetadata.siteUrl}/${post.fields.slug}`,
						identifier: post.fields.slug,
						title: post.frontmatter.title,
					}}
				/>
			</div>

			<nav className="flex flex-wrap justify-between">
				{previous ? (
					<div className="mr-auto">
						<p className="text-sm text-left text-smoke-400">Previous</p>
						<Link to={previous.fields.slug} className="break-word text-smoke-900 hover:text-gray-800" rel="prev">
							← {previous.frontmatter.title}
						</Link>
					</div>
				) : (
					<span></span>
				)}
				{next ? (
					<div className="ml-auto">
						<p className="text-sm text-right text-smoke-400">Next</p>
						<Link to={next.fields.slug} className="break-word text-smoke-900" rel="next">
							{next.frontmatter.title} →
						</Link>
					</div>
				) : (
					<span></span>
				)}
			</nav>
		</Layout>
	);
};

export const Article = ({ author, title, date, readingTime, children }) => (
	<article className="w-full">
		<header className="flex flex-col justify-center items-start">
			<h1 className="pt-4 font-bold text-4xl md:text-5xl leading-tight text-gray-800">{title}</h1>

			<small className="align-self-start mt-2 text-xs leading-none text-smoke-500">
				by {author} &middot; {date} &middot; {readingTime}
			</small>
		</header>
		{children}
	</article>
);

export default BlogPostTemplate;

export const pageQuery = graphql`
	query BlogPostBySlug($slug: String!, $author: String!) {
		site {
			siteMetadata {
				siteUrl
			}
		}
		post: markdownRemark(fields: { slug: { eq: $slug } }) {
			id
			excerpt(pruneLength: 160)
			html
			frontmatter {
				title
				date(formatString: "MMMM DD, YYYY")
				description
				published
				featuredImage {
					childImageSharp {
						fixed(width: 600) {
							...GatsbyImageSharpFixed
						}
					}
				}
			}
			fields {
				readingTime {
					text
				}
			}
		}
		author: markdownRemark(frontmatter: { name: { eq: $author } }) {
			html
			frontmatter {
				name
				avatar {
					childImageSharp {
						fixed(width: 150) {
							...GatsbyImageSharpFixed
						}
					}
				}
			}
		}
	}
`;
